@use "../mixins";
@use "../variables";

.Orders {

  .header {
    display: none;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  @include mixins.md-up {

    .header {

      display: flex;
      padding: 1rem;
      gap: 1rem;

      div:nth-child(1) {
        width: 18%;
      }
      div:nth-child(2) {
        width: 30%;
      }
      div:nth-child(3) {
        width: 10%;
        text-align: center;
      }
      div:nth-child(4) {
        width: 22%;
      }
      div:nth-child(5) {
        width: 20%;
        text-align: right;
      }

    }

    .body {
      background-color: white;
      padding: 1rem;
    }

  }

  @include mixins.lg-up {
    .header {
      div:nth-child(2) {
        width: 25%;
      }
      div:nth-child(3) {
        width: 20%;
      }
      div:nth-child(4) {
        width: 17%;
      }
    }
  }

  @include mixins.lg-up {
    .header {
      div:nth-child(2) {
        width: 20%;
      }
      div:nth-child(3) {
        width: 25%;
      }
    }
  }

}
