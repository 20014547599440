@use "variables";
@use "mixins";

@font-face {
  font-family: RocGrotesk;
  src: url('/font/roc-grotesk-regular.woff2');
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: RocGrotesk;
  src: url('/font/roc-grotesk-bold.woff2');
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: RocGrotesk;
  src: url('/font/roc-grotesk-wide-bold.woff2');
  font-weight: 600;
  font-stretch: expanded;
}

@font-face {
  font-family: RocGrotesk;
  src: url('/font/roc-grotesk-condensed-bold.woff2');
  font-weight: 600;
  font-stretch: condensed;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: RocGrotesk, sans-serif;
  color: variables.$color-text;
}

h1, h2 {
  text-transform: uppercase;
  color: variables.$color-primary;
  font-weight: 600;
  margin: 0;
  line-height: 1;
  hyphens: auto;
  &.narrow {
    max-width: 550px;
  }
  & + p, & + h1, & + h2, & + h3 {
    margin-top: 4rem;
  }
}

h1 {
  font-size: min(16vw, 4rem);
  font-stretch: condensed;
  &.jumbo {
    font-size: 6.125rem;
    font-stretch: expanded;
    @include mixins.lg-up {
      font-size: 12.25rem;
    }
  }
}

h2 {
  font-size: min(10vw, 2.5rem);
  font-stretch: expanded;
}

h3 {
  margin: 0;
  font-size: 1.125rem;
  svg {
    vertical-align: -.375rem;
    margin-right: .5rem;
  }
  & + p {
    margin-top: 1rem;
  }
}

a {
  color: variables.$color-secondary;
  text-decoration: none;
  font-weight: 600;
  transition: color 250ms linear;
  &:hover {
    color: variables.$color-text;
  }
}

button.link {
  font-family: RocGrotesk, sans-serif;
  font-size: 1rem;
  padding: 0;
  background: none;
  border: none;
  font-weight: bolder;
  cursor: pointer;
  color: variables.$color-secondary;
  transition: color 250ms linear;
  &:hover {
    color: variables.$color-primary;
  }
}

p {
  margin: 0;
  line-height: 1.2;
  & + h2 {
    margin-top: 2rem;
  }
  & + *, & + ul {
    margin-top: 1rem;
  }
}

ul {
  margin: 0;
  padding: 0 0 0 1rem;
  li:not(:first-child) {
    margin-top: .5rem;
  }
  & + p {
    margin-top: 1rem;
  }
  & + h2 {
    margin-top: 2rem;
  }
}

img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

hr {
  all: unset;
  display: block;
  border-top: 1px solid variables.$color-secondary;
}

.visually-hidden {
  position: absolute!important;
  width: 1px!important;
  height: 1px!important;
  padding: 0!important;
  margin: -1px!important;
  overflow: hidden!important;
  clip: rect(0,0,0,0)!important;
  white-space: nowrap!important;
  border: 0!important;
}

.small {
  font-size: .75rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.break-all {
  word-break: break-all;
}

.color-secondary {
  color: variables.$color-secondary !important;
}

.highlight-text {
  font-weight: 600;
  color: variables.$color-primary;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-025 {
  margin-top: .25rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-1-75 {
  margin-top: 1.75rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.bt {
  border-top: 1px solid variables.$color-secondary;
}

@include mixins.lg-up {
  .hidden-lg-up {
    display: none !important;
  }
}

@include mixins.up-to-lg {
  .hidden-up-to-lg {
    display: none !important;
  }
}
