@use "../variables";
@use "../mixins";

.Header {
  height: 70px;
  & > div {
    box-shadow: 0 0 2rem #00000017;
    background-color: white;
    position: fixed;
    z-index: 3;
    width: 100%;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: .5rem 1rem;
      & > a:first-child, & > a > img:first-child {
        height: 3.375rem;
      }
      a {
        color: variables.$color-primary;
        display: flex;
      }
      button {
        all: unset;
        margin-left: 1rem;
        cursor: pointer;
        display: flex;
        @include mixins.up-to-lg {
          margin-left: .5rem;
        }
      }
      flex-wrap: wrap;
      column-gap: .5rem;
      row-gap: 1rem;
      margin-bottom: -1rem;
      & > div:nth-child(2) {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin: 0 0 0 auto;
        flex-basis: auto;
        @include mixins.up-to-lg {
          gap: .5rem;
        }
        @include mixins.up-to-sm {
          gap: .5rem;
          .Nav.inline ul {
            gap: .5rem;
          }
        }
      }
      .Nav:nth-child(3) {
        flex: 0 1 calc(100% + 2rem);
        margin: -1rem -1rem 1rem -1rem;
        padding: 0 1rem;
        ul {
          padding: .5rem 0 0 0;
          gap: 0;
          a {
            position: relative;
            padding: .5rem 0;
            &:hover, &.active {
              &:before {
                content: "";
                display: block;
                position: absolute;
                width: calc(100% + 1rem);
                height: 100%;
                left: -.5rem;
                top: 0;
                background-color: variables.$color-light-grey;
                z-index: -1;
              }
            }
          }
        }
      }
      @include mixins.lg-up {
        row-gap: unset;
        margin-bottom: 0;
        justify-content: unset;
        & > div:nth-child(2) {
          order: 2;
          button {
            display: none;
          }
          .Nav ul {
            gap: .75rem;
          }
        }
        .Nav:nth-child(3) {
          flex: 0 1 auto;
          margin: 0 0 0 1rem;
          padding: 0;
          ul {
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            gap: 1rem;
            li:nth-child(n+5) {
              display: none;
            }
            a {
              padding: .5rem;
              top: 2px;
              &:after {
                content: "";
                display: block;
                height: 1px;
                width: 0;
                position: absolute;
                bottom: 2px;
                left: 0;
                background-color: variables.$color-primary;
                transition: width 250ms ease-in-out;
              }
              &:hover {
                background-color: transparent;
                &:before {
                  display: none;
                }
                &:after {
                  width: 100%;
                }
              }
              &.active {
                &:after {
                  width: 100%;
                }
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
      @include mixins.xl-up {
        .Nav:nth-child(3) {
          margin: 0 0 0 5rem;
          ul {
            gap: 2rem;
          }
        }
      }
    }
  }

  .Dropdown .selected-item .icon {
    padding-right: 3px;
    svg {
      position: relative;
      top: -1px;
    }
  }

}
