@use "../variables";

.Widget {

  background-color: white;
  padding: 1.25rem;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  h3 {
    color: variables.$color-secondary;
    text-transform: uppercase;
    font-weight: 600;
    font-stretch: condensed;
    & + * {
      margin-top: 1rem;
    }
  }

  & > p, & > div {
    flex-basis: 100%;
  }

  a:not(.Button) {
    color: variables.$color-primary;
  }

  .Button {
    margin-top: 2rem;
  }

}
