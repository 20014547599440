@use "../variables";

.Balance {

  display: inline-flex;
  gap: 4px;
  align-items: center;
  color: white;
  font-family: RocGrotesk, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: .02em;

  &.color-blue {
    color: variables.$color-secondary;
  }

  &.size-medium {
    gap: 6px;
    font-size: 14px;
    svg {
      height: 24px;
      width: auto;
    }
  }

  &.size-big {
    gap: 10px;
    font-size: 45px;
    svg {
      height: 42px;
      width: auto;
    }
  }

  span {
    position: relative;
    top: 1px;
  }

}
