@use "../mixins";

.Container {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  @include mixins.sm-up {
    max-width: 540px;
  }
  @include mixins.md-up {
    max-width: 720px;
  }
  @include mixins.lg-up {
    max-width: 960px;
  }
  @include mixins.xl-up {
    max-width: 1140px;
  }
}
