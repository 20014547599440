@use '../variables';
@use '../mixins';

.LineItem {

  display: flex;
  gap: 1rem;
  background-color: white;
  &:nth-child(odd) {
    background-color: #f9f9f9;
  }
  &:first-child {
    background-color: transparent;
    display: none;
    @include mixins.lg-up {
      display: flex;
    }
  }
  @include mixins.lg-up {
    align-items: center;
  }
  padding: 1rem;

  .image {
    flex-shrink: 0;
    width: 2rem;
    img {
      max-height: 5rem;
    }
    @include mixins.sm-up {
      width: 4rem;
    }
    @include mixins.md-up {
      width: 5rem;
      padding-right: 1rem;
    }
    @include mixins.lg-up {
      order: 1;
      padding-right: 2rem;
    }
  }

  .text {

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    font-stretch: condensed;

    @include mixins.lg-up {
      flex-direction: row;
      align-items: center;
      order: 2;
      .title {
        flex-grow: 1;
      }
      .unit-price {
        width: 25%;
      }
      .total-price {
        order: 1;
        width: 18%;
        .Price {
          justify-content: flex-end;
        }
      }
      .AmountSelection, .amount {
        width: 22%;
        text-align: center;
        font-size: 1rem;
        font-stretch: normal;
        color: variables.$color-secondary;
      }
    }

    .headline {
      text-transform: uppercase;
      font-stretch: normal;
    }

    .brand {
      text-transform: uppercase;
      font-weight: 600;
    }

    .name {
      text-transform: uppercase;
      font-weight: 600;
      color: variables.$color-secondary;
      font-size: 1.5rem;
    }

    .description {
      font-stretch: normal;
      color: variables.$color-secondary;
      font-size: 0.875rem;
      line-height: 1.1;
      margin-top: .25rem;
    }

    @include mixins.up-to-lg {
      .AmountSelection {
        max-width: 150px;
      }
    }

  }

  .action {
    @include mixins.lg-up {
      width: 44px;
      padding-right: 2rem;
    }
    .Button {
      padding: .125rem;
      @include mixins.sm-up {
        padding: .5rem;
      }
      &:hover {
        background-color: variables.$color-secondary;
        border-color: variables.$color-secondary;
        color: white;
      }
      span.icon svg {
        height: 1rem;
        width: 1rem;
        @include mixins.sm-up {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }
    &.hidden {
      visibility: hidden;
    }
  }

  &.description {
    .text {
      color: variables.$color-secondary;
      font-stretch: normal;
      .AmountSelection {
        height: auto;
        display: block;
      }
      .total-price {
        text-align: right;
      }
    }
  }

}
