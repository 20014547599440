@use "../mixins";
@use "../variables";

.Credit {
  .credit-widget {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    color: variables.$color-secondary;
    background-color: white;
    padding: 1rem;
    margin: 2rem 0;

    .balance {
      font-size: 2rem;
      text-transform: uppercase;
      padding: 1rem;
      gap: 1rem;
      color: variables.$color-primary;
      text-align: center;
      span {
        font-weight: bold;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      margin-inline: auto;
      align-items: center;

      &.shop > span {
        color: variables.$color-text;
        text-align: center;

        .Abbreviation {
          color: variables.$color-secondary;
        }
      }
    }
  }

  .shop:nth-child(1) {order: 2;}
  .shop:nth-child(2) {order: 1;}
  .shop:nth-child(3) {order: 3;}

  @include mixins.sm-up {
    .credit-widget {
      flex-direction: row;
    }
    .shop:nth-child(1) {order: 1;}
    .shop:nth-child(2) {order: 2;}
    .shop:nth-child(3) {order: 3;}
  }

  @include mixins.md-up {
    .credit-widget {
      padding: 1rem 0;
    }
  }
  .table-headline {
    text-align: center;
    margin: 4rem 0 0 0;
    color: variables.$color-secondary;
  }
}
