@use "../variables";
@use "../mixins";

.Order {

  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: .25rem;
  color: variables.$color-secondary;
  cursor: pointer;

  .property {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    line-height: 1.1;
    .value {
      text-align: right;
    }
  ;
  }

  @include mixins.md-up {

    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 0;

    .property .key {
      display: none;
    }

    .property .value {
      text-align: left;
    }

    .property:nth-child(1) {
      width: 18%;
      div {
        text-decoration: underline;
      }
    }
    .property:nth-child(2) {
      width: 30%;
    }
    .property:nth-child(3) {
      width: 10%;
      justify-content: center;
    }
    .property:nth-child(4) {
      width: 22%;
    }
    .property:nth-child(5) {
      width: 20%;
      justify-content: flex-end;
    }

  }

  @include mixins.lg-up {
    .property:nth-child(2) {
      width: 25%;
    }
    .property:nth-child(3) {
      width: 20%;
    }
    .property:nth-child(4) {
      width: 17%;
    }
  }

  @include mixins.lg-up {
    .property:nth-child(2) {
      width: 20%;
    }
    .property:nth-child(3) {
      width: 25%;
    }
  }

}
