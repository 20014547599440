@use "../variables";
@use "../mixins";

.BrandSlider {

  position: relative;
  width: 100%;
  overflow: hidden;

  .slider-inner {

    position: absolute;
    display: flex;
    user-select: none;
    height: 100%;

    .slider-item {

      height: auto;
      user-select: none;
      position: relative;
      background-color: #f4f4f4;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 80%;
        height: 80%;
        top: 10%;
        left: 10%;

        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        -webkit-filter: grayscale(1); /* Safari 6.0 - 9.0 */
        filter: grayscale(1);

        pointer-events: none;
        user-drag: none;
        -webkit-user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;

      }

      &:hover {
        & > span {
          -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
          filter: grayscale(0);
        }
      }

    }

    &.transition {
      transition: margin-left linear 100ms;
    }

  }

  @media (max-width: 576px) {
    height: calc(100px / 1.65);
    .slider-item {
      width: 100px;
    }
    .slider-item:not(:last-child) {
      margin-right: .25rem;
    }
  }

  @include mixins.sm-up {
    height: calc(147px / 1.65);
    .slider-item {
      width: 147px;
    }
    .slider-item:not(:last-child) {
      margin-right: .5rem;
    }
  }

  @include mixins.md-up {
    height: calc(153px / 1.65);
    .slider-item {
      width: 153px;
    }
  }

  @include mixins.lg-up {
    height: calc(166px / 1.65);
    .slider-item {
      width: 166px;
    }
  }

}
