@use "../variables";

.AmountSelection {

  height: 2.5rem;
  display: grid;
  grid-template-columns: 2.5rem 1fr 2.5rem;
  max-width: 160px;

  button {
    all: unset;
    background-color: variables.$color-primary;
    cursor: pointer;
    color: white;
    display: grid;
    place-content: center;
    svg {
      width: 1rem;
      height: 1rem;
      stroke-width: 3;
    }
    &:disabled {
      opacity: .5;
      cursor: default;
    }
    &:focus {
      z-index: 1;
      outline: 2px solid variables.$color-secondary;
      outline-offset: -2px;
    }
    &.hidden {
      visibility: hidden;
    }
  }

  input {
    all:unset;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    background-color: darken(variables.$color-background, 5%);
    color: variables.$color-secondary;
    display: grid;
    place-content: center;
    position: relative;
    padding-top: 2px;
    font-size: 1rem;
    font-stretch: expanded;
    font-weight: 600;
    width: 100%;
  }

}
