@use '../variables';

.PointOfSale {
  padding: 3px .5rem 3px 3px;
  display: flex;
  align-items: center;
  gap: .5rem;
  font-size: .875rem;
  background-color: variables.$color-secondary;
  p {
    line-height: 1.1;
    position: relative;
    top: 1px;
  }
  .Abbreviation {
    border: none;
    height: 34px;
    width: 34px;
  }
}

.Dropdown .selected-item .PointOfSale {
  padding-right: 3px;
}

.Dropdown .PointOfSale {
  background-color: transparent;
}

.Dropdown .selected-item .PointOfSale > p {
  display: none;
}
