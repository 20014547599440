@use '../variables';

.AbbreviationAndBalance {

  .beam {

    background-color: variables.$color-secondary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;

  }

  .legend {

    margin-top: 5px;
    display: flex;
    justify-content: space-between;

    span {
      font-stretch: expanded;
      font-size: 0.625rem;
      font-weight: 600;
      text-transform: uppercase;
    }

  }

}
