.IconAndText {

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: .25rem;

  &.center {
    justify-content: center;
  }

  &.left {
    justify-content: flex-start;
  }

  svg {
    flex-shrink: 0;
  }

  span {
    position: relative;
    top: 2px;
  }

}
