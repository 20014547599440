@use "../variables";
@use "../mixins";

.PointsBanner {

  background-image:
    linear-gradient(to bottom, rgba(144, 155, 185, .3), rgba(0, 13, 29, 0.5)),
    url('../../public/points-banner-background.webp');
  background-size: cover;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 25px;

  @include mixins.md-up {
    flex-direction: row;
    gap: 0;
  }

  & > .text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    @include mixins.md-up {
      padding: 0 30px;
    }
    .Overline {
      color: white;
      margin: 0;
      letter-spacing: 0.05em;
    }
    h3 {
      color: white;
      font-size: 32px;
      line-height: 38.4px;
      letter-spacing: .02em;
      font-weight: 700;
      max-width: 500px;
    }
    .buttons {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      .Button {
        width: 200px;
        span {
          align-items: center;
        }
      }
    }
  }

  .Abbreviation {

    margin-bottom: -25px;
    border-bottom-width: 0;
    height: 37px;

    @include mixins.md-up {
      margin-top: 0;
      border-bottom-width: 3px;
      height: 40px;
      border-right-width: 0;
      width: 37px;
    }

  }

  .balance {
    flex-shrink: 0;
    max-width: 100%;
    height: 100px;
    @include mixins.md-up {
      width: 250px;
      height: 250px;
    }
    background-color: variables.$color-secondary;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
