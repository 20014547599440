@use "../mixins";

.FilterSortControl {

  display: flex;
  position: fixed;
  z-index: 2;
  top: 70px;
  left: 0;
  width: 100%;

  .Button {
    width: 50%;
    z-index: 1;
  }

  .Dropdown {
    width: 50%;
    .selected-item, .DropdownItem {
      justify-content: center;
    }
    .selection {
      min-width: 100%;
    }
  }

  @include mixins.md-up {
    position: static;
    justify-content: right;
    .Button {
      display: none;
    }
    .Dropdown {
      width: auto;
      .selected-item, .DropdownItem {
        justify-content: flex-start;
      }
      .selection {
        min-width: 120%;
      }
    }
  }

}
