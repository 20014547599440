@use "../variables";
@use "../mixins";

.ProductCard {

  position: relative;
  text-align: center;
  background-color: variables.$color-light-grey;
  font-stretch: condensed;
  display: flex;
  flex-direction: column;

  .tags {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: .125rem;
    position: absolute;
    right: .5rem;
    top: .5rem;
    div {
      font-size: .875rem;
      text-transform: uppercase;
      color: variables.$color-secondary;
      border: 2px solid variables.$color-secondary;
      background-color: white;
      width: max-content;
      padding: .25rem;
      line-height: 1;
      span {
        position: relative;
        top: 2px;
      }
      &.danger {
         color: variables.$color-danger;
         border-color: variables.$color-danger;
       }
    }
  }

  img {
    background-color: white;
    width: calc(100% - 4rem);
    height: 10rem;
    object-fit: contain;
    padding: 2rem;
  }

  .brand {
    background-color: white;
    text-transform: uppercase;
    &:after {
      content: '\200B';
    }
  }

  .name {
    background-color: white;
    color: variables.$color-secondary;
    font-size: 1.5rem;
    padding-bottom: 1rem;
    flex-grow: 1;
  }

  .description {
    font-stretch: normal;
    color: variables.$color-secondary;
    font-size: 0.875rem;
  }

  .AmountSelection {
    margin: .5rem auto 2rem auto;
  }

}
