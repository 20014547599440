@use "../variables";
@use "../mixins";

.CreditTransactions {
  .table {
    .header {
      display: none;
      @include mixins.md-up {
        display: flex;
        padding: 1rem 0;
        gap: 1rem;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      gap: 1rem;
      @include mixins.md-up {
        margin-top: 0;
        gap: 0
      }

      .table-row {
        background-color: white;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        color: variables.$color-secondary;
        padding: .5rem;

        .property {
          display: flex;
          gap: 1rem;
          align-items: center;
          justify-content: space-between;
          line-height: 1.1;
          padding: 0 .5rem;

          .value {
            text-align: right;

            img {
              max-width: 2.5rem;
              max-height: 2.5rem;
              margin: 0;
            }
          }
        }

        @include mixins.md-up {
          flex-direction: row;
          align-items: center;
          gap: 1rem;
          padding: .5rem 0;

          .property {
            height: 2.5rem;
            .key {
              display: none;
            }
            .value {
              width: 100%;
              text-align: inherit;
            }
          }
        }
      }
    }
    @include mixins.md-up {
      .header, .body .table-row {
        div:nth-child(1) {
          width: 20%;
          text-align: center;
        }

        div:nth-child(2) {
          width: 10%;
          text-align: center;
        }

        div:nth-child(3) {
          width: 20%;
          text-align: center;
        }

        div:nth-child(4) {
          width: 30%;
          text-align: center;
        }

        div:nth-child(5) {
          width: 20%;
          text-align: center;
        }
      }
    }
  }
}
