@mixin sm-up {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin md-up {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg-up {
  @media (min-width: 992px) {
    @content;
  }
}
@mixin xl-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin up-to-sm {
  @media (max-width: 575px) {
    @content;
  }
}

@mixin up-to-md {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin up-to-lg {
  @media (max-width: 991px) {
    @content;
  }
}
@mixin up-to-xl {
  @media (max-width: 1199px) {
    @content;
  }
}
