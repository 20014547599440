@use "../variables";

.LabelAndText {

  color: variables.$color-secondary;

  span {
    font-size: .625rem;
    font-stretch: expanded;
    font-weight: 600;
    text-transform: uppercase;
  }

  & + .LabelAndText {
    margin-top: .5rem;
  }

}
