@use "../variables";

.Checkbox {

  display: flex;
  gap: 1rem;
  font-size: .75rem;
  font-weight: 600;
  color: variables.$color-secondary;
  hyphens: auto;

  input {
    display: none;
    & + label {
      display: flex;
      width: 1.25rem;
      height: 1.25rem;
      background-color: white;
      border: 2px solid variables.$color-secondary;
      svg {
        visibility: hidden;
        width: 100%;
        height: 100%;
        position: relative;
        top: 2px;
      }
    }
    &:checked + label svg {
      visibility: visible;
    }
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .25rem;
  }

  a {
    color: variables.$color-primary;
    hyphens: none;
  }

  &.has-error input + label {
    border-color: variables.$color-danger;
  }

}
