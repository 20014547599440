@use "../variables";
@use "../mixins";

.OrderItem {

  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: .25rem;
  color: variables.$color-secondary;

  .property {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    line-height: 1.1;
    height: 2.5rem;
    .value {
      width: 100%;
      img {
        max-width: 2.5rem;
        max-height: 2.5rem;
        margin: 0;
      }
    }
  ;
  }

  @include mixins.md-up {

    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 0;

    .property .key {
      display: none;
    }

    div:nth-child(1) {
      width: 5%;
      div {
        display: flex;
        justify-content: center;
      }
    }
    div:nth-child(2) {
      width: 20%;
    }
    div:nth-child(3) {
      width: 35%;
    }
    div:nth-child(4) {
      width: 10%;
    }
    div:nth-child(5) {
      width: 5%;
      text-align: center;
    }
    div:nth-child(6) {
      width: 10%;
      text-align: right;
    }
    div:nth-child(7) {
      width: 15%;
      text-align: right;
    }

  }

}
