@use "../variables";

.DownloadCard {

  img {
    height: 250px;
    padding: 2rem;
    width: calc(100% - 4rem);
    object-fit: contain;
    background-color: #f4f4f4;
  }

  .title {
    color: variables.$color-primary;
    font-size: .875rem;
    margin-top: .5rem;
    text-transform: uppercase;
    font-stretch: expanded;
  }

  .description {
    color: variables.$color-text;
    font-size: .8125rem;
    margin-bottom: .5rem;
    font-weight: 400;
    text-transform: uppercase;
  }

}

