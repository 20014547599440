@use "../variables";
@use "../mixins";

.InfoBoxFirstOrderCredits {

  .heading {
    border-bottom: none;
    padding-bottom: 0;
  }

  .upper {

    display: flex;
    flex-direction: column;
    @include mixins.md-up {
      flex-direction: row;
      align-items: flex-start;
    }

    .text {
      order: 1;
      margin: 2rem 0;
      text-align: center;
      @include mixins.md-up {
        order: 0;
        margin-top: 0;
        text-align: left;
      }
      h2 {
        text-transform: none;
        font-stretch: normal;
        line-height: 1.2;
        font-size: 1.25rem;
        @include mixins.md-up {
          font-size: 1.825rem;
        }
      }
      p {
        margin: 1rem auto 0 auto;
        max-width: 500px;
        @include mixins.md-up {
          font-size: 1.125rem;
        }
      }
    }

    img {
      order: 0;
      max-width: 150px;
      @include mixins.md-up {
        order: 1;
        max-width: 160px;
      }
    }

  }

  .lower {

    background-color: variables.$color-secondary;
    color: white;
    padding: 2rem 0;

    img {
      max-width: calc(100% - 2rem);
      margin: 0;
      @include mixins.md-up {
        max-width: calc(100% - 3rem);
      }
    }

    .cta {
      font-size: 1.25rem;
      margin-top: 2rem;
      padding: 0 13%;
      text-align: center;
      @include mixins.md-up {
        text-align: left;
        font-size: 1.5rem;
      }
    }

    .info {
      margin-top: 2rem;
      padding: 0 13%;
      text-align: center;
      font-size: 1rem;
      span {
        white-space: nowrap;
      }
      @include mixins.md-up {
        font-size: 1.25rem;
      }
    }

  }

}
