@use "../variables";

.DropdownItem {
  display: flex;
  background-color: variables.$color-secondary;
  color: white;
  cursor: pointer;
  transition-property: background-color, border-color;
  transition-duration: 250ms;
  &:hover {
    background-color: darken(variables.$color-secondary, 9%);
  }
  .text {
    font-size: .875rem;
    font-stretch: expanded;
    font-weight: 600;
    line-height: 1.2;
    padding: calc(.625rem + 1px) 1rem .625rem 1rem;
    position: relative;
    top: 1px;
    white-space: nowrap;
    text-transform: uppercase;
  }
}
