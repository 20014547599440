@use "../mixins";
@use "../variables";

.DottedCard {
  box-sizing: border-box;
  color: variables.$color-secondary;
  & > div {
    background-color: white;
  }
  @include mixins.sm-up {
    border: 1px dashed variables.$color-secondary;
    display: flex;
    & > div {
      margin: .5rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      .Widget {
        flex-grow: 1;
      }
    }
  }
}

@include mixins.up-to-sm {
  .Section:not(.background) .DottedCard .Widget {
    padding: 0;
  }
}
