@use "../variables";
@use "../mixins";

.Nav {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: .4rem;
    li {
      line-height: 1;
      margin: 0;
      &:empty {
        display: none;
      }
    }
  }
  &.inline {
    ul {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }
    li {
      display: contents;
    }
  }
  &.uppercase {
    a {
      font-stretch: expanded;
      text-transform: uppercase;
    }
  }
  &.expandable {
    overflow: hidden;
    transition: height 250ms ease-out;
    @include mixins.lg-up {
      transition: none;
      height: auto !important;
    }
  }
  svg {
    color: variables.$color-secondary;
  }
}
