@use '../variables';
@use '../mixins';

.ProductFilter {

  background-color: variables.$color-background;
  position: fixed;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 3;
  transition: top 250ms ease-out;

  &.expanded {
    top: 0;
  }

  .title {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    height: 40px;
    span {
      color: variables.$color-secondary;
      font-size: 1.25rem;
      font-stretch: expanded;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 1;
      position: relative;
      top: 1px;
    }
    button {
      all: unset;
      color: variables.$color-grey;
      display: flex;
      cursor: pointer;
    }
  }

  .body-wrapper {
    padding: 1rem;
    .body {
      height: calc(100vh - 40px - 38px - 2rem);
      overflow-y: auto;
    }
  }

  .footer {
    display: flex;
  }

  @include mixins.md-up {

    position: static;
    z-index: 0;

    .title {
      display: none;
    }

    .body-wrapper {
      padding: 0;
      .body {
        height: auto;
        overflow-y: visible;
      }
    }

    .footer {
      display: none;
    }

  }

  ul.brands {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    li {
      display: flex;
      align-items: center;
      background-color: white;
      cursor: pointer;
      margin: 0;
      flex: 0 0 calc((100% - 1rem) / 2);
      @media (min-width: 400px) {
        flex-basis: calc((100% - 2rem) / 3);
      }
      @include mixins.md-up {
        flex: 0 0 calc((100% - 1rem) / 2);
      }
      @include mixins.xl-up {
        flex-basis: calc((100% - 2rem) / 3);
      }
      img {
        box-sizing: border-box;
        padding: .25rem;
        &:hover {
          filter: saturate(1.25);
        }
      }
      &.active {
        outline: 2px solid variables.$color-primary;
        outline-offset: -2px;
        img {
          filter: saturate(1.25);
        }
      }
    }
  }

  ul.product-types {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      cursor: pointer;
      padding: .25rem;
      color: variables.$color-primary;
      background-color: white;
      display: flex;
      align-items: center;
      gap: 1rem;
      span {
        flex-grow: 1;
      }
      &.active {
        outline: 2px solid variables.$color-primary;
        outline-offset: -2px;
      }
    }
  }

}
