@use "../variables";
@use "../mixins";

.Pagination {

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  color: variables.$color-secondary;

  .arrow-left, .arrow-right {
    display: flex;
    cursor: pointer;
    &:hover {
      color: variables.$color-primary;
    }
    &.inactive {
      opacity: .5;
      pointer-events: none;
    }
    @include mixins.sm-up {
      &.arrow-right {
        order: 1;
      }
    }
  }

  .pages {
    flex: 0 1 100%;
    text-align: center;
    @include mixins.sm-up {
      flex: 0 1 auto;
    }
    button {
      all: unset;
      user-select: none;
      font-stretch: expanded;
      font-weight: 600;
      line-height: 1;
      padding: calc(.25rem + 3px) .25rem .25rem .25rem;
      cursor: pointer;
      &:hover {
        color: variables.$color-primary;
      }
      &.active {
        pointer-events: none;
        text-decoration: underline;
      }
    }
  }

}
