@use "../mixins";

.UnevenRow {
  display: flex;
  gap: 1.75rem;
  & > :first-child {
    flex-shrink: 0;
    flex-basis: 240px;
    @include mixins.lg-up {
      flex-basis: 320px;
    }
    @include mixins.xl-up {
      flex-basis: 380px;
    }
  }
  & > :last-child {
    flex-grow: 1;
  }
}
