@use "../variables";
@use "../mixins";

.Promotion {

  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include mixins.md-up {
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }

  img {
    @include mixins.md-up {
      width: 66%;
    }
  }

  .text {

    display: flex;
    flex-direction: column;
    gap: 1rem;

    ul {
      color: variables.$color-primary;
      list-style: none;
      padding-left: 1.5rem;
      li {
        position: relative;
        &:before {
          position: absolute;
          content: '';
          width: .75rem;
          height: .75rem;
          background-color: variables.$color-primary;
          border-radius: 50%;
          left: -1.25rem;
          top: .25rem;
        }
      }
    }

  }

}
