@use "../mixins";

.Download {

  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding: 1rem;
  background-color: white;
  flex-wrap: wrap;
  gap: .5rem 1rem;
  justify-content: space-between;

  :first-child {
    flex: 1 0 100%;
    @include mixins.sm-up {
      flex-basis: auto;
    }
  }

}
