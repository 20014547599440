@use "../mixins";
@use "../variables";

.OrderItems {

  .header {
    display: none;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .order-widget {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    color: variables.$color-secondary;
    background-color: white;
    padding: 1rem;
    margin: 2rem 0;
    div {
      display: flex;
      flex-direction: column;
      margin-inline: auto;
      align-items: center;
      &>span {
        color: variables.$color-text;
        text-align: center;
        .Abbreviation {
          color: variables.$color-secondary;
        }
      }
    }
  }

  .shop:nth-child(1) {order: 2;}
  .shop:nth-child(2) {order: 1;}
  .shop:nth-child(3) {order: 3;}

  @include mixins.sm-up {
    .order-widget {
      flex-direction: row;
    }
    .shop:nth-child(1) {order: 1;}
    .shop:nth-child(2) {order: 2;}
    .shop:nth-child(3) {order: 3;}
  }

  @include mixins.md-up {
    .order-widget {
      padding: 1rem 0;
    }

      .header {

      display: flex;
      padding: 1rem;
      gap: 1rem;

      div:nth-child(1) {
        width: 5%;
      }
      div:nth-child(2) {
        width: 20%;
      }
      div:nth-child(3) {
        width: 35%;
      }
      div:nth-child(4) {
        width: 10%;
      }
      div:nth-child(5) {
        width: 5%;
        text-align: center;
      }
      div:nth-child(6) {
        width: 10%;
        text-align: right;
      }
      div:nth-child(7) {
        width: 15%;
        text-align: right;
      }

    }

    .body {
      background-color: white;
      padding: 1rem;
    }

  }
}
