@use "../variables";
@use "../mixins";

.Section {

  box-sizing: border-box;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @include mixins.lg-up {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &.background {
    background-color: variables.$color-background;
  }

  &.background-image {
    display: flex;
    align-items: center;
    background-image: var(--background-image-default);
    background-size: cover;
    .Container {
      width: 100%;
    }
    .Row {
      gap: 1rem;
    }
    @include mixins.md-up {
      background-image: var(--background-image-md);
    }
    @include mixins.lg-up {
      min-height: calc(100vh - 70px);
      .Row {
        gap: 5rem;
      }
    }
    @media (min-width: 1920px) {
      background-size: 1920px;
    }
    .Overline {
      color: variables.$color-primary;
      @include mixins.xl-up {
        font-size: 1.25rem;
      }
    }
    h1 {
      color: white;
      @include mixins.xl-up {
        font-size: 4rem;
        line-height: .9;
      }
    }
  }

}
