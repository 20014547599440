@use "../variables";
@use "../mixins";

.Footer {

  font-size: .875rem;
  line-height: 1.2;

  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    li {
      flex: auto;
      margin: 0;
      @include mixins.md-up {
        max-width: 12.5rem;
      }
      @include mixins.lg-up {
        width: 12.5rem;
      }
    }
    a {
      font-size: .75rem;
    }
    img {
      height: 1.25rem;
      margin-top: 0.75rem;
    }
  }

  .headline {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1;
    padding-bottom: .5rem;
    margin-bottom: .75rem;
    border-bottom: 1px solid variables.$color-text;
    font-weight: 600;
    font-stretch: condensed;
  }

  strong {
    text-transform: uppercase;
    font-weight: 600;
    font-stretch: condensed;
    color: variables.$color-primary;
    font-size: 1rem;
  }

  .panel .Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    & > img {
      max-height: 5rem;
    }
  }

  .baseboard {
    padding-top: 1rem;
    padding-bottom: 1rem;
    .Container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      a {
        font-weight: 600;
        font-stretch: normal;
      }
    }
  }

}
