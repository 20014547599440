@use "../variables";

.ErrorModal {
  .body {
    ul {
      color: variables.$color-danger;
      margin-top: .25rem;
    }
  }
}
