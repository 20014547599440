@use "../variables";

.DecoratedIcon {
  position: relative;
  display: inline-flex;
  span {
    position: absolute;
    font-stretch: condensed;
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.5rem;
    text-align: center;
    padding-top: 1px;
    box-sizing: border-box;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: variables.$color-primary;
    color: white;
    top: calc(-1.5rem / 2);
    right: calc(-1.5rem / 2);
  }
}
