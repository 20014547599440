@use "../variables";

.ImageAndTextCard {

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  img {
    margin: 0;
  }

  h3 {
    color: variables.$color-primary;
    text-transform: uppercase;
    position: relative;
    top: 2px;
  }

  p {
    flex-grow: 1;
    margin: 0;
  }

  a {
    text-transform: uppercase;
    font-size: .875rem;
    margin: 0;
  }

}
