@use "../mixins";

.Row {

  display: flex;
  flex-wrap: wrap;
  gap: 1.75rem;

  &.align-center {
    align-items: center;
  }

  &.justify-end  {
    justify-content: flex-end;
  }

  &.justify-center  {
    justify-content: center;
  }

  & > * {
    flex: var(--columns-default, 1 0 0);
    @include mixins.sm-up {
      flex: var(--columns-sm, var(--columns-default, 1 0 0));
    }
    @include mixins.md-up {
      flex: var(--columns-md, var(--columns-sm, var(--columns-default, 1 0 0)));
    }
    @include mixins.lg-up {
      flex: var(--columns-lg, var(--columns-md, var(--columns-sm, var(--columns-default, 1 0 0))));
    }
    @include mixins.xl-up {
      flex: var(--columns-xl, var(--columns-lg, var(--columns-md, var(--columns-sm, var(--columns-default, 1 0 0)))));
    }
  }

  & + .Row {
    margin-top: 4rem;
  }

}
