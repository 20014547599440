@use "../variables";

.Error {
  font-size: .875rem;
  font-weight: 600;
  font-stretch: condensed;
  color: variables.$color-danger;
  text-transform: uppercase;
  &:after {
    content: '\200B';
  }
}
