@use '../variables';

.Price {
  display: flex;
  gap: .25rem;
  font-size: 1.5rem;
  font-stretch: condensed;
  color: variables.$color-primary;
  justify-content: center;
  align-items: center;
  span {
    font-size: 0.875rem;
  }
  &.align-left {
    justify-content: flex-start;
  }
  &.align-right {
    justify-content: flex-end;
  }
}
