@use "../variables";
@use "../mixins";

.EmptyCart {
  background-color: white;
  padding: 1.25rem;
  text-align: center;
  .DecoratedIcon {
    color: variables.$color-secondary;
    margin-top: 1.5rem;
    svg {
      width: 62px;
      height: 62px;
    }
    span {
      height: 3rem;
      width: 3rem;
      line-height: 3.3rem;
      font-size: 1.75rem;
      top: -1.5rem;
      right: -1.5rem;
    }
  }
  h2 {
    font-size: 1.5rem;
    font-stretch: condensed;
    margin-top: 1rem;
  }
  a {
    display: block;
    margin-top: .25rem;
    font-weight: normal;
  }
  @include mixins.sm-up {
    display: flex;
    gap: 4rem;
    text-align: left;
    align-items: center;
    padding: 2.5rem;
    h2 {
      margin: 0;
    }
  }
}
