@use "../variables";

.Abbreviation {
  flex-shrink: 0;
  display: inline-grid;
  place-content: center;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border: 3px solid variables.$color-secondary;
  color: variables.$color-secondary;
  background-color: white;
  text-transform: uppercase;
  font-size: 1.125rem;
  font-stretch: condensed;
  font-weight: 600;
  line-height: 1;
  span {
    position: relative;
    top: 2px;
  }
}
