@use '../variables';

.LinkButton {

  all: unset;

  font-weight: 600;
  cursor: pointer;
  color: variables.$color-primary;

  &.secondary {
    color: variables.$color-secondary;
  }

}
