@use "../variables";
@use "../mixins";

.GreetingText {

  padding: 1rem 0;

  h2, p {
    color: variables.$color-secondary;
  }

  h2 {
    text-transform: unset;
    font-stretch: normal;
    font-size: 2rem;
  }

  p {
    margin: 0;
  }

}
