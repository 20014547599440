@use "../variables";
@use "../mixins";
@use "../component/button";

.NewsletterSubscription {

  h3 {
    color: variables.$color-primary;
    text-transform: uppercase;
  }

  & > div {
    flex: 0 1 100%;
    @include mixins.sm-up {
      flex: 0 1 calc((100% - 2 * 1.75rem) / 3);
    }
  }

  .hs-form-required {
    color: variables.$color-danger;
    margin-left: .25rem;
  }

  .hs_email {
    label {
      span {
        font-size: .875rem;
        font-weight: 600;
        font-stretch: condensed;
        color: variables.$color-secondary;
        text-transform: uppercase;
      }
    }
    .input {
      min-height: 1.75rem;
      display: flex;
      border: 1px solid variables.$color-secondary;
      padding: .25rem;
      background-color: white;
      line-height: 1;
      input {
        all: unset;
        width: 100%;
        font-stretch: normal;
        padding: 0 .75rem 0 .5rem;
        color: variables.$color-secondary;
        font-weight: 600;
        &::placeholder {
          opacity: .5;
        }
      }
    }
  }

  #hs-subscription-form {
    flex-grow: 1;
  }

  .hs-error-msgs {
    list-style-type: none;
    padding: 0;
    margin: .25rem 0;
    font-weight: 600;
    color: variables.$color-danger;
    text-transform: uppercase;
    font-stretch: 75%;
  }

  .legal-consent-container {
    .hs-form-booleancheckbox-display {
      display: flex;
      gap: 1rem;
      & > span {
        margin: 0;
      }
    }
    margin: .25rem 0;
    input {
      flex-shrink: 0;
      height: 20px;
      width: 20px;
    }
    ul {
      list-style-type: none;
      padding: 0;
    }
    p {
      font-weight: 600;
      font-size: .75rem;
      line-height: 1.2;
      color: variables.$color-secondary;
      a {
        color: variables.$color-primary;
      }
    }
  }

  .hs-button {
    font-weight: 600;
    font-stretch: expanded;
    text-transform: uppercase;
    line-height: 1.2;
    font-size: .875rem;
    font-family: RocGrotesk, sans-serif;

    display: flex;
    width: 100%;
    max-width: 250px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    cursor: pointer;
    padding: calc(.5rem + 1px) 1rem .5rem 1rem;

    transition-property: background-color, border-color, color;
    transition-duration: 250ms;

    background-color: variables.$color-secondary;
    color: white;
    border: 2px solid variables.$color-secondary;
  }

  .hs_error_rollup {
    display: none;
  }

}
