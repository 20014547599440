@use "../variables";

.AccordionItem {

  background-color: white;
  padding: 1rem;

  .head {

    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    text-transform: uppercase;
    color: variables.$color-primary;
    font-weight: 600;
    font-stretch: expanded;
    font-size: .875rem;
    cursor: pointer;

    svg {
      flex-shrink: 0;
      width: 2rem;
      height: 2rem;
      transition: transform 250ms linear;
      color: variables.$color-grey;
    }

  }

  .body-wrapper {
    overflow: hidden;
    transition: max-height 200ms ease-out;
    .body {
      padding-top: 1rem;
      line-height: 1.25rem;
      a {
        white-space: nowrap;
      }
    }
  }

}
