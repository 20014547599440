.feather {

  &.feather-loader {
    @keyframes pulsate {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    line {
      animation: pulsate 1s linear infinite;
    }
    @for $i from 2 through 8 {
      line:nth-child(#{$i}) {
        animation-delay: #{125 * $i - 1125}ms;
      }
    }
  }

  &.right {
    transform: rotate(0deg);
  }

  &.down {
    transform: rotate(90deg);
  }

  &.left {
    transform: rotate(180deg);
  }

  &.up {
    transform: rotate(-90deg);
  }

}
