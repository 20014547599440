@use '../variables';

.Button {

  font-weight: 600;
  font-stretch: expanded;
  text-transform: uppercase;
  line-height: 1.2;
  font-size: .875rem;
  font-family: RocGrotesk, sans-serif;

  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  padding: calc(.5rem + 1px) 1rem .5rem 1rem;

  transition-property: background-color, border-color, color;
  transition-duration: 250ms;

  background-color: variables.$color-secondary;
  color: white;
  border: 2px solid variables.$color-secondary;

  &:disabled {
    pointer-events: none;
  }

  &.inline {
    display: inline-flex;
    width: auto;
  }

  &:hover {
    background-color: darken(variables.$color-secondary, 9%);
    color: white;
    border: 2px solid darken(variables.$color-secondary, 9%);
  }

  &.inverted {
    background-color: white;
    color: variables.$color-secondary;
    border: 2px solid variables.$color-secondary;
    &:hover {
      color: variables.$color-text;
      border-color: variables.$color-text;
    }
  }

  &.primary {
    background-color: variables.$color-primary;
    border-color: variables.$color-primary;
    &:hover {
      background-color: darken(variables.$color-primary, 9%);
      border-color: darken(variables.$color-primary, 9%);
    }
  }

  &:disabled {
    opacity: .5;
  }

  &:focus:not(:active) {
    outline: 2px solid black;
  }

  span {

    display: flex;

    &.text {
      position: relative;
      top: 1px;
      text-align: center;
      &:empty {
        display: none;
      }
    }

    &.icon svg {
      width: 1rem;
      height: 1rem;
    }

  }

}
